<template>
  <div id="canvas-holder" style="width: 100%">
    <canvas id="chart-gauge"></canvas>
    <div
      class="flex justify-between items-center pb-4 m-auto w-5/6 md-max:w-6/6"
    >
      <div class="flex items-center">
        <div class="flex justify-center items-center bad">
          <i class="text-xl text-white far fa-thumbs-down"></i>
        </div>
        <div class="good_value">
          <h1 class="block">{{ data.bad }} %</h1>
          <span class="block">Bad</span>
        </div>
      </div>
      <div class="flex items-center">
        <div class="flex justify-center items-center good">
          <i class="text-xl text-white far fa-thumbs-up"></i>
        </div>
        <div class="good_value">
          <h1 class="block">{{ data.good }} %</h1>
          <span class="block">Good</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js'
// eslint-disable-next-line
import Gauge from 'chartjs-gauge'
export default {
  name: 'GaugeChart',
  props: ['bad', 'good', 'data'],
  data() {
    return {
      instance: null,
      options: {
        type: 'gauge',
        data: {
          labels: ['Critical', 'Bad', 'Poor', 'Okay', 'Good', 'Awesome'],
          datasets: [
            {
              data: [17, 34, 51, 68, 85, 100],
              value: 0,
              backgroundColor: [
                '#EC0505',
                '#f85353',
                '#ff8e3a',
                '#FFBF3A',
                '#26EF55',
                '#14CD20',
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: false,
            text: 'Gauge',
          },
          tooltips: {
            enabled: true,
            titleFontFamily: 'Roboto, sans-serif',
            titleFontSize: 13,
            titleAlign: 'center',
            titleSpacing: 3,
            titleMarginBottom: 0,
          },
          layout: {
            padding: {
              bottom: 50,
            },
          },
          cutoutPercentage: 80,
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 3,
            // Needle width as the percentage of the chart area width
            widthPercentage: 2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: -280,
            // The color of the needle
            color: 'rgba(0, 0, 0, 1)',
          },
          valueLabel: {
            display: true,
            fontSize: '20',
            bottomMarginPercentage: '13',
            formatter: (value) => {
              // console.log('gauge-val', value)
              let v = Math.round(value)
              if (v <= 17) {
                return 'Critical'
              }
              if (v > 17 && v <= 34) {
                return 'Bad'
              }
              if (v > 34 && v <= 51) {
                return 'Poor'
              }
              if (v > 51 && v <= 68) {
                return 'Okay'
              }
              if (v > 68 && v <= 85) {
                return 'Good'
              }
              if (v > 85) {
                return 'Awesome'
              }
            },
            color: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            padding: {
              top: 10,
              bottom: 10,
            },
          },
          plugins: {
            datalabels: {
              display: true,
              formatter: function(value, context) {
                return context.chart.data.labels[context.dataIndex]
              },
              //color: function (context) {
              //  return context.dataset.backgroundColor;
              //},
              color: 'rgba(0, 0, 0, 1.0)',
              //color: 'rgba(255, 255, 255, 1.0)',
              backgroundColor: null,
              font: {
                size: 15,
                weight: 'bold',
              },
            },
          },
        },
      },
    }
  },
  mounted() {
    this.options.data.datasets[0].value = this.data.good /* - this.data.bad */
    this.init()
  },
  computed: {
    isValue() {
      return this.data
    },
  },
  watch: {
    isValue: function(updatedData) {
      this.options.data.datasets[0].value = updatedData.good - updatedData.bad
      this.init()
    },
  },
  methods: {
    init() {
      var ctx = document.getElementById('chart-gauge').getContext('2d')
      this.instance = new Chart(ctx, this.options)
    },
  },
}
</script>

<style lang="scss" scoped>
.chartjs-render-monitor {
  display: block;
  width: 550px !important;
  height: 280px !important;
  margin: auto !important;
  @media (max-width: 990px) {
    height: 175px !important;
    width: 340px !important;
  }
}
.good {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #14cd20;
  @media (max-width: 990px) {
    width: 40px;
    height: 40px;
  }
}
.bad {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #ec0505;
  @media (max-width: 990px) {
    width: 40px;
    height: 40px;
  }
}
.good_value {
  margin-left: 10px;
  h1 {
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
    @media (max-width: 990px) {
      font-size: 14px;
    }
  }
  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #989898;
    @media (max-width: 990px) {
      font-size: 12px;
    }
  }
}
</style>
