<template>
  <div class="grid grid-cols-12 mt-6 flex items-center justify-center">
    <div class="flex col-span-3 items-center">{{ title }}</div>

    <div
      class="relative col-span-7 pl-3 "
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
    >
      <div style="background: #f2f2f2;">
        <progress-bar-x
          :value="value"
          :animated="true"
          :height="height"
          :rounded="0"
          :color="color"
        />
      </div>

      <div class="flex flex-wrap items-center popup" v-if="show_popup">
        <div class="w-full">
          <span class="chart_time">{{ start }} - {{ end }}</span>
        </div>
        <div class="w-full">
          <span class="chart_title">{{ title }}</span>
        </div>
        <div class="flex items-center w-full">
          <div class="w-1/2">
            <p class="text-sm text-gray-600">(none)</p>
          </div>
          <div class="w-1/2 text-right">
            <p class="chart_title">{{ count }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex col-span-2 items-center pl-3 text-2xl">{{ count }}</div>
  </div>
</template>

<script>
import ProgressBarX from '@/components/analytics/charts/ProgressBarX'
import { changeDateFormat2 } from '@/utils/ConvertDate.js'

export default {
  name: 'HorizontalBarChartAlt',
  components: {
    ProgressBarX,
  },
  data() {
    return {
      show_popup: false,
      index: -1,
      start: '',
      end: '',
    }
  },
  methods: {
    mouseOver() {
      this.show_popup = true
      this.start = changeDateFormat2(this.start_date)
      this.end = changeDateFormat2(this.end_date)
    },
    mouseLeave() {
      this.show_popup = false
    },
  },
  props: {
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    value: {
      type: Number,
    },
    color: {
      type: String,
      default: 'teal',
    },
    height: {
      type: Number,
      default: 24,
    },
    start_date: {
      required: false,
    },
    end_date: {
      required: false,
    },
  },
  computed: {},
}
</script>

<style>
.popup {
  position: absolute;
  padding: 15px;
  top: 10px;
  right: 20px;
  width: 300px;
  height: 150px;
  background: white;
  box-shadow: 1px 3px 7px 0px #b3b3b3;
  z-index: 10;
  border-radius: 5px;
}
.chart_time {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818191;
}
.chart_title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.chart_value {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
</style>
