<template>
  <div class="px-4 pt-2 pb-2 min-h-screen page-gradient">
    <section>
      <content-section class="px-0 mt-3">
        <div
          class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
        >
          <span class="md-max:mb-4">Fleet Analytics</span>

          <DashboardDatePicker
            @apply-date="onChangeDate($event, 'line')"
            @cancel-date="handleCancelDate($event, 'line')"
          />
        </div>

        <AreaChartCombo
          :fleet-id="fleetId"
          :date-range="dateRange"
          :class="`mt-2`"
        />
      </content-section>
    </section>

    <section>
      <content-section class="mt-2">
        <div
          class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
        >
          <span class="md-max:mb-4">Active vehicles by hours</span>
          <DashboardDatePicker
            @apply-date="onChangeDate($event, 'activity')"
            @cancel-date="handleCancelDate($event, 'activity')"
          />
        </div>

        <template v-if="activityDataLoaded">
          <heat-map-chart :data="vehicles" />
        </template>
      </content-section>

      <TheSuspense class="p-4 bg-white" v-if="!activityDataLoaded">
        <SuspenseImg :height="`360px`" />
      </TheSuspense>
    </section>

    <section>
      <div class="grid grid-cols-1 lg:grid-cols-2 md:gap-x-4">
        <content-section>
          <div class="grid grid-cols-1">
            <div
              class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
            >
              <span class="md-max:mb-4">Fleet Health Feedback</span>
              <DashboardDatePicker
                @apply-date="onChangeDate($event, 'health')"
                @cancel-date="handleCancelDate($event, 'health')"
              />
            </div>

            <div v-if="gaugeDataLoaded">
              <gauge-chart :data="guage_chart" v-if="guage_chart != null" />
            </div>

            <TheSuspense class="p-4 bg-white" v-if="!gaugeDataLoaded">
              <SuspenseImg :height="`280px`" />
            </TheSuspense>
          </div>
        </content-section>

        <content-section>
          <div class="block">
            <div
              class="flex flex-wrap justify-between items-center my-5 section-title md-max:justify-center"
            >
              <span class="md-max:mb-4">Fleet Conditions</span>
              <DashboardDatePicker
                @apply-date="onChangeDate($event, 'condition')"
                @cancel-date="handleCancelDate($event, 'condition')"
              />
            </div>

            <div class="mt-4" v-if="fleetHealthDataLoaded">
              <horizontal-bar-chart-alt
                v-for="(item, index) in fleet_conditions"
                :key="index"
                :title="item.status"
                :count="item.total"
                :value="item.perspectivePercentage"
                :end_date="end_date"
                :start_date="start_date"
                :class="`my-2`"
                :color="`#3a9eba`"
              />
            </div>

            <TheSuspense class="p-4 bg-white" v-if="!fleetHealthDataLoaded">
              <SuspenseImg :height="`300px`" />
            </TheSuspense>
          </div>
        </content-section>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { AnalyticsConfig } from '@/config/AnalyticsConfig'

// import MapChart from '@/components/analytics/MapChart'
import GaugeChart from '@/components/analytics/GaugeChart'
// import MapChartAlt from '@/components/analytics/MapChartAlt'
import HeatMapChart from '@/components/analytics/HeatMapChart'
import AreaChartCombo from '@/components/analytics/AreaChartCombo'
// import HorizontalBarChart from '@/components/analytics/HorizontalBarChart'
import HorizontalBarChartAlt from '@/components/analytics/HorizontalBarChartAlt'

// import FleetDropdown from '@/components/picker/FleetDropdown'
import ContentSection from '@/components/layout/ContentSection'
import DashboardDatePicker from '@/components/picker/date-range/DashboardDatePicker'

export default {
  name: 'Home',
  components: {
    // FleetDropdown,
    ContentSection,
    DashboardDatePicker,

    GaugeChart,
    // MapChartAlt,
    HeatMapChart,
    AreaChartCombo,
    HorizontalBarChartAlt,
  },

  data() {
    return {
      req: {
        area: {
          busy: true,
        },
      },

      // filters
      orgId: '',
      // fleetId: '',
      // date range picker - used as a prop for AreaChartCombo
      dateRange: {
        start: dayjs()
          .subtract(1, 'week')
          .format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },

      isLoaded: false,
      isLoading: false,
      guage_chart: null,
      currentOrganization: null,
      currentFleet: null,

      // other
      dropdownLoaded: false,
      tripChartLoaded: false,
      gaugeDataLoaded: false,
      fleetHealthDataLoaded: false,
      activityDataLoaded: false,

      fleet_conditions: [],

      map_data: [],
      sorted_map_data: [],
      reduced_map_data: [],

      vehicles: [],
      health_feedback: [],

      start_date: '',
      end_date: '',

      getOrganization: [],
      orgPlaceholder: '',

      fleetsInfo: [],
    }
  },

  computed: {
    fleetId() {
      return this.$route.params.id
    },
  },

  async mounted() {
    const userInfo = this.$store.getters['auth/userInfo']

    if (userInfo.is_superadmin) {
      this.orgPlaceholder = 'All Organizations'
    } else {
      this.orgPlaceholder = 'My Organizations'
    }

    await this.$http
      .get(`/dashboard/fleets/?dropdown`)
      .then((res) => {
        this.fleetsInfo = res.data.data
      })
      .catch((err) => {
        console.warn(err)
      })

    await this.$http
      .get(`/dashboard/organizations/?dropdown`)
      .then((res) => {
        this.getOrganization = res.data.data
      })
      .catch((err) => {
        console.warn(err)
      })

    this.dropdownLoaded = true

    if (this.start_date == '') {
      this.start_date = this.getLast7Day()
    }

    if (this.end_date == '') {
      this.end_date = this.getToday()
    }

    let event = {
      start: this.start_date,
      end: this.end_date,
    }

    // await this.getMapData(event)
    await this.getGaugeData(event)
    await this.getFleetData(event)
    await this.getActivityData(event)
  },
  methods: {
    onChangeOrg(e) {
      this.orgId = e
    },

    onChangeFleet(e) {
      this.fleetId = e
    },

    async onChangeDate(event, type) {
      if (type == 'line') {
        this.dateRange = event
      } else {
        this.start_date = event.start
        this.end_date = event.end

        if (type === 'maps') {
          await this.getMapData(event)
          return
        }
        if (type === 'activity') {
          await this.getActivityData(event)
          return
        }
        if (type === 'health') {
          await this.getGaugeData(event)
          return
        }
        if (type === 'condition') {
          await this.getFleetData(event)
          return
        }
      }
    },

    // todo: rename to fetch_Type_Data()
    async getMapData(event) {
      this.tripChartLoaded = false

      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&fleet=' +
        this.fleetId

      const api = AnalyticsConfig.api.map_chart + query

      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        alert('noMapRes')
        return
      }

      this.map_data = resData

      const sorted_map_data = this.map_data.sort(function(a, b) {
        let tripA = a.trips
        let tripB = b.trips
        return tripB - tripA
      })
      const withTrips = sorted_map_data.filter((item) => {
        return item.trips > 0
      })
      const withoutTrips = sorted_map_data.filter((item) => {
        return item.trips < 1
      })
      const totalItems = withTrips.length + withoutTrips.length
      const maxTollerable =
        withTrips.length > 5 ? 5 : totalItems > 5 ? 5 : totalItems
      this.reduced_map_data = this.map_data.slice(0, maxTollerable)

      // add percentage based on maximum trips perspective
      const maxTripValue = Math.max(...withTrips.map((item) => item.trips))
      this.reduced_map_data.forEach((item) => {
        const p = maxTripValue > 0 ? (item.trips * 100) / maxTripValue : 0
        item.perspectivePercentage = Number(p.toFixed())
      })

      this.tripChartLoaded = true
      console.log(
        'maxTollerable = ',
        maxTollerable,
        'reduced_map_data = ',
        this.reduced_map_data
      )
    },

    async getGaugeData(event) {
      this.gaugeDataLoaded = false
      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&fleet=' +
        this.fleetId

      const api = AnalyticsConfig.api.gauge_chart + query
      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        alert('norRes')
        return
      }
      let detail = {
        good: resData.good,
        bad: resData.bad,
      }
      this.guage_chart = detail

      this.gaugeDataLoaded = true
    },

    async getFleetData(event) {
      this.fleetHealthDataLoaded = false

      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&fleet=' +
        this.fleetId

      const api = AnalyticsConfig.api.bar_chart + query

      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        alert('norRes')
        return
      }
      const transformedData = resData.sort((a, b) =>
        a.total > b.total ? -1 : a.total < b.total ? 1 : 0
      )
      const maxTotalValue = Math.max(
        ...transformedData.map((item) => item.total)
      )

      transformedData.forEach((item) => {
        const p =
          maxTotalValue > 0 ? Number((item.total * 100) / maxTotalValue) : 0
        item.perspectivePercentage = p
      })
      this.fleet_conditions = transformedData
      console.log('fleet_conditions = ', this.fleet_conditions)
      this.fleetHealthDataLoaded = true
    },

    async getActivityData(event) {
      this.activityDataLoaded = false
      let start_date = event.start
      let end_date = event.end

      let query =
        '?start_date=' +
        start_date.split('T')[0] +
        '&end_date=' +
        end_date.split('T')[0] +
        '&fleet=' +
        this.fleetId

      let api = ''

      let step = '&start_hour=6&step_hour=2'
      api = AnalyticsConfig.api.activity_chart + query + step

      let resData = await this.$http
        .get(api)
        .then((res) => res.data.data)
        .catch((err) => console.log('err = ', err))

      if (!resData) {
        alert('norRes')
        return
      }

      this.vehicles = resData
      this.activityDataLoaded = true
    },

    // Helpers
    getToday() {
      const today = dayjs()
      return today.format(this.format)
    },

    getLast7Day() {
      const last7day = dayjs().subtract(6, 'day')
      return last7day.format(this.format)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(176, 199, 237, 1) 0%,
    rgba(203, 217, 240, 0.7)
  );
}
.main-title {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title-faded {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9c9ca6;
}

.mc {
  max-width: 95%;
  margin: 0 auto;
}
.ph {
  min-height: 400px;
}
</style>

<style>
.bg-my-gray-200 {
  background-color: #eceff1;
}
</style>
