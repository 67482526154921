export const AnalyticsConfig = {
  api: {
    gauge_chart: '/dashboard/analytics/gauge-chart/',
    bar_chart: '/dashboard/analytics/bar-chart/',
    map_chart: '/dashboard/analytics/map-chart/',
    line_chart: '/dashboard/analytics/line-chart/',
    activity_chart: '/dashboard/analytics/activity-chart/',

    trip_heatmap: '/dashboard/analytics/demand-heatmap/',
  },
}
