<template>
  <div class="py-2 px-2 bg-white rounded">
    <!-- tabs -->
    <div class="x-tabs overflow-x-auto">
      <div
        v-for="(tab, index) in chartTabs"
        :key="`x-tab-${index}`"
        :class="{
          'x-tab-item': true,
          'is-active': tab.isActive,
          'is-inactive': !tab.isActive,
        }"
        @click="onChangeTab({ tab, index })"
      >
        <div class="titles">
          <div>
            <span class="text-title" v-text="`#`" />
            <span class="text-title" v-text="tab.title" />
          </div>

          <div
            class="tooltip-question-mark"
            v-tooltip="
              `This week v/s Last week: ${tab.percentage.value}% ${
                tab.percentage.isPositive ? 'increased' : 'decreased'
              }`
            "
          />
        </div>

        <div class="numbers">
          <div class="count">
            <span>{{
              tab.type === 'trip_revenue' && tab.meta.currency
                ? tab.meta.currency.symbol
                : ''
            }}</span>
            {{ tab.total }}
            <span class="">
              {{ tab.type === 'avg_trip_time' ? 'min' : '' }}
            </span>
          </div>

          <div
            :class="
              `percentage ${
                tab.percentage.isPositive ? 'positive' : 'negative'
              }`
            "
          >
            <i
              :class="
                `fas ${
                  tab.percentage.isPositive ? 'fa-arrow-up' : 'fa-arrow-down'
                }`
              "
            />
            <span v-text="tab.percentage.value" />%
          </div>
        </div>
      </div>
    </div>
    <!-- /tabs -->

    <div class="relative mt-1">
      <ApexCharts
        :type="`area`"
        :height="350"
        :series="series"
        :options="chartOptions"
      />
      <loading :active="req.busy" :is-full-page="false" :z-index="150" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { useEndpoints } from '@/composables'
import { nFormatter, parseISO8601Duration } from '@/utils'

export default {
  name: 'AreaChartCombo',

  props: {
    dateRange: {
      type: Object,
      default: () => ({
        start: dayjs()
          .subtract(1, 'week')
          .format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      }),
    },

    orgId: {
      type: String,
      default: '',
    },

    fleetId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      req: {
        busy: false,
      },

      series: [
        // {
        //   name: 'Series',
        //   data: [],
        // },
      ],

      chartOptions: {
        chart: {
          id: 'areaChartCombo',
          type: 'area',
          height: 350,
          zoom: {
            enabled: false,
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: 'straight',
        },

        // title: {
        //   text: 'Trip',
        //   align: 'left',
        // },

        labels: [],

        xaxis: {
          type: 'datetime',
        },

        yaxis: {
          opposite: true,
          forceNiceScale: true,
        },

        legend: {
          horizontalAlign: 'left',
        },

        noData: {
          text: 'Loading...',
        },

        tooltip: {
          // eslint-disable-next-line no-unused-vars
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            function formatDate(timestamp) {
              return dayjs(timestamp).format('DD MMM, YY')
            }

            // data: { y:value, x:timestamp, past: { y:value, x:timestamp } }
            const data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex]

            // console.log('tooltip', data, w)

            return `<div class="overflow-hidden w-40 text-sm">
                        <div class="flex justify-between items-center py-1 px-1 text-gray-800 border-b bg-my-gray-200">
                          <span>${formatDate(data.x)}</span>

                          <span class="font-bold">vs</span>

                          <span>${formatDate(data.past.x)}</span>
                        </div>

                        <div class="flex justify-between items-center py-2 px-2 text-sm text-gray-700">
                          <div class="flex gap-2 items-center">
                            <div class="font-bold">${data.y}</div>
                          </div>

                            <div class="flex">
                              <div class="w-2 h-2 rounded-full border border-blue-600"></div>
                            <div class="w-2 h-2 rounded-full border border-gray-600"></div>
                            </div>


                          <div class="flex gap-2 items-center">
                            <div class="font-bold">${data.past.y}</div>
                          </div>
                        </div>
                      </div>`
          },
        },
      },

      chartTabs: [
        {
          type: 'user',
          title: 'New Users',
          isActive: true,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
        {
          type: 'trip',
          title: 'Trips',
          isActive: false,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
        {
          type: 'vehicle',
          title: 'Active Vehicles',
          isActive: false,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
        {
          type: 'report',
          title: 'Reports',
          isActive: false,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
        {
          type: 'avg_trip_time',
          title: 'Avg Trip Time',
          isActive: false,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
        {
          type: 'trip_revenue',
          title: 'Revenue',
          isActive: false,
          total: 0,
          max: 0,
          percentage: {
            value: 0,
            isPositive: true,
          },
          series: [],
          meta: {},
        },
      ],
    }
  },

  computed: {
    getEndpoint() {
      const activeTab = this.chartTabs.find((tab) => tab.isActive)
      const type = activeTab ? activeTab.type : 'user'

      return useEndpoints.analytics.areaChart({
        type,
        orgId: this.orgId,
        fleetId: this.fleetId,
        dateRange: this.dateRange,
      })
    },

    watcherString() {
      return (
        this.dateRange.start +
        '::' +
        this.dateRange.end +
        '::' +
        this.fleetId +
        '::' +
        this.orgId
      )
    },
  },

  watch: {
    // getEndpoint can be used instead
    async watcherString(v) {
      console.log('watcherString', v)
      await this.fetchAll().then(() => {
        const index = this.chartTabs.findIndex((tab) => tab.isActive)
        this.onChangeTab({ index })
      })
      // await this.fetchAndUpdate()
    },
  },

  async created() {
    await this.fetchAll().then(() => {
      this.onChangeTab({ index: 0 })
    })
    // await this.fetchAndUpdate()
  },

  methods: {
    async onChangeTab({ index }) {
      // console.log('onChangeTab', tab, index)
      this.chartTabs.forEach((t) => {
        t.isActive = false
      })
      this.chartTabs[index].isActive = true

      // either await this.fetchAndUpdate() or
      // use fetchAll() then -> fetched data instead

      // using fetched data, as we must have the data already
      const { series, max } = this.chartTabs[index]
      this.updateChart({ series, max })
    },

    async fetchAndUpdate() {
      this.req.busy = true
      await this.$http
        .get(this.getEndpoint)
        .then(({ data }) => {
          const { type, current, past } = data
          const { total, max, compare } = current

          const tab = this.chartTabs.find((tab) => tab.type === type)
          if (!tab) return

          tab.max = max
          tab.total = total
          tab.series = this.getSeries({ current, past, type })
          tab.percentage = {
            value: Math.abs(compare),
            isPositive: compare >= 0,
          }

          this.updateChart({ series: tab.series, max })
        })
        .catch((e) => console.log('fetchAndUpdateErr', e, e.response))
        .finally(() => (this.req.busy = false))
    },

    async fetchAll() {
      const urls = []
      const types = [
        'user',
        'trip',
        'vehicle',
        'report',
        'avg_trip_time',
        'trip_revenue',
      ]
      types.forEach((type) => {
        urls.push(
          useEndpoints.analytics.areaChart({
            type,
            orgId: this.orgId,
            fleetId: this.fleetId,
            dateRange: this.dateRange,
          })
        )
      })

      const requests = []
      urls.forEach((url) => {
        requests.push(this.$http.get(url))
      })

      this.req.busy = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            console.log(responses)

            responses.forEach((res) => {
              const data = res.data

              const { type, current, past } = data
              const { total, max, compare } = current

              const tab = this.chartTabs.find((tab) => tab.type === type)
              if (tab) {
                tab.max = max
                tab.total = nFormatter(total, 1)
                tab.series = this.getSeries({ current, past, type })
                tab.percentage = {
                  value: Math.abs(compare),
                  isPositive: compare >= 0,
                }

                // trip_revenue has currency data
                if (type === 'trip_revenue') {
                  tab.meta.currency = data.currency
                }
              }
            })
          })
        )
        .catch((e) => console.log('fetchAllErr', e, e.response))
        .finally(() => (this.req.busy = false))
    },

    getSeries({ current, past, type }) {
      const series = []
      const dateDiff = dayjs(this.dateRange.end).diff(
        this.dateRange.start,
        'days'
      )

      // avg trip time needs to be parsed as ISO 8601 & convert to minutes
      if (type === 'avg_trip_time') {
        // const c = []
        current.data = current.data.map((item) =>
          item ? parseISO8601Duration(item).minutes : 0
        )
        past.data = past.data.map((item) =>
          item ? parseISO8601Duration(item).minutes : 0
        )
        // console.log(current.data, 'avg_trip_time')
      }

      // current & past literally has same structure with same amount of data
      // except current has a current.compare value

      const currentLength = current.data.length
      if (currentLength !== dateDiff) {
        // determine the max number of data to be filled
        const maxFill = dateDiff - currentLength

        // fill in missing dates
        const theDate =
          currentLength > 0
            ? dayjs(current.date[current.data.length - 1]).add(1, 'days')
            : dayjs(this.dateRange.start)

        for (let n = 0; n < maxFill; n++) {
          const y = 0
          const pastDate = dayjs(theDate)
            .clone()
            .subtract(1, 'weeks')
            .add(n, 'days')
            .utc()
            .format()

          const currentDate = dayjs(theDate)
            .clone()
            .add(n, 'days')
            .utc()
            .format()

          past.data.push(y)
          past.date.push(pastDate)

          current.data.push(y)
          current.date.push(currentDate)
        }

        // console.log('filling data', current, past)
      }

      // map the data to the series
      current.data.forEach((d, i) => {
        series.push({
          y: d,
          x: current.date[i],
          past: {
            y: past.data[i],
            x: past.date[i],
          },
        })
      })

      return series
    },

    updateChart({ series, max }) {
      // update apex chart
      this.$apexcharts.exec('areaChartCombo', 'updateOptions', {
        series: [{ data: series }],
        yaxis: {
          opposite: true,
          forceNiceScale: true,
          max: (v) => (v === max ? v + 1 : v),
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.x-tabs {
  display: flex;
  margin-bottom: 5px;
  column-gap: 2px;
}

.x-tab-item {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  height: 60px;
  width: 110px;
  padding: 2px 10px;
  border-radius: 2px;
  border-top: 3px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    background-color: #f6f6f6;
  }

  &.is-active {
    border-top: 3px solid #0095f4;
    background-color: #f6f6f6;
  }

  &.is-inactive {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #7a7a8d;
    font-size: 10px;
  }

  .tooltip-question-mark {
    font-size: 0.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;
    color: rgba(75, 85, 99, 1);
    background-color: rgba(243, 244, 246, 1);
    border-radius: 9999px;
    border: 1px solid rgba(156, 163, 175, 1);
    cursor: default;

    &::after {
      content: '?';
    }
  }

  .numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #2e2e39;

    .count {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #101011;
      font-weight: bold;
    }
    .percentage {
      display: flex;
      align-items: center;
      column-gap: 2px;

      &.positive {
        color: #09aa35;
      }
      &.negative {
        color: #d90a20;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    & {
      width: 135px;
      height: 65px;
    }

    .text-title {
      font-size: 13px;
    }
    .text-number {
      font-size: 18px;
    }
  }
}
</style>
